var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual-intro'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('tab-intro'), _vm._l(_vm.items, function (item, index) {
    return [_c('div', {
      key: index,
      class: index != 0 ? 'pt-40 pt-md-60' : ''
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('v-img', {
      staticClass: "w-100",
      attrs: {
        "src": item.img,
        "max-width": "384",
        "aspect-ratio": 384 / 480
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "8"
      }
    }, [_c('div', {
      staticClass: "pl-lg-24"
    }, [_c('v-card', {
      staticClass: "mb-24",
      attrs: {
        "flat": "",
        "tile": "",
        "color": "#ECF7E9"
      }
    }, [_c('div', {
      staticClass: "pa-12 pa-md-16"
    }, [_c('v-row', {
      staticClass: "row--x-small",
      attrs: {
        "align": "center"
      }
    }, [item.job ? _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('span', {
      staticClass: "chip tit tit--sm white--text primary"
    }, [_vm._v(" " + _vm._s(item.job) + " ")])]) : _vm._e(), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('h3', {
      staticClass: "tit tit--md line-height-1"
    }, [_vm._v(_vm._s(item.name))])]), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('p', {
      staticClass: "page-text page-text--lg primary--text line-height-1"
    }, [_vm._v(_vm._s(item.nameEn))])])], 1)], 1)]), _c('div', {
      staticClass: "page-text page-text--lg line-height-15"
    }, _vm._l(item.history, function (item) {
      return _c('div', {
        key: item.title,
        staticClass: "mb-24"
      }, [_c('v-row', {
        attrs: {
          "no-gutters": ""
        }
      }, [_c('v-col', {
        staticClass: "mb-8 mb-md-0",
        attrs: {
          "cols": "12",
          "md": "auto"
        }
      }, [_c('div', {
        staticClass: "min-w-md-60px mr-lg-12"
      }, [_c('p', {
        staticClass: "font-weight-bold grey-1--text"
      }, [_vm._v(" " + _vm._s(item.title) + " ")])])]), _c('v-col', _vm._l(item.children, function (child) {
        return _c('v-row', {
          key: child.title,
          attrs: {
            "no-gutters": ""
          }
        }, [child.title ? _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('div', {
          staticClass: "min-w-60px min-w-40px min-w-md-60px"
        }, [_c('p', [_vm._v(" " + _vm._s(child.title) + " ")])])]) : _vm._e(), _c('v-col', [_c('p', {
          domProps: {
            "innerHTML": _vm._s(child.info)
          }
        })])], 1);
      }), 1)], 1)], 1);
    }), 0)], 1)])], 1)], 1)];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }