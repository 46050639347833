<template>
    <client-page>
        <sub-visual-intro />

        <section class="section">
            <v-container>
                <tab-intro />

                <template v-for="(item, index) in items">
                    <div :class="index != 0 ? 'pt-40 pt-md-60' : ''" :key="index">
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-img :src="item.img" max-width="384" :aspect-ratio="384 / 480" class="w-100" />
                            </v-col>
                            <v-col cols="12" md="8">
                                <div class="pl-lg-24">
                                    <v-card flat tile color="#ECF7E9" class="mb-24">
                                        <div class="pa-12 pa-md-16">
                                            <v-row align="center" class="row--x-small">
                                                <v-col v-if="item.job" cols="auto">
                                                    <span class="chip tit tit--sm white--text primary">
                                                        {{ item.job }}
                                                    </span>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <h3 class="tit tit--md line-height-1">{{ item.name }}</h3>
                                                </v-col>
                                                <v-col cols="auto">
                                                    <p class="page-text page-text--lg primary--text line-height-1">{{ item.nameEn }}</p>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-card>
                                    <div class="page-text page-text--lg line-height-15">
                                        <div v-for="item in item.history" :key="item.title" class="mb-24">
                                            <v-row no-gutters>
                                                <v-col cols="12" md="auto" class="mb-8 mb-md-0">
                                                    <div class="min-w-md-60px mr-lg-12">
                                                        <p class="font-weight-bold grey-1--text">
                                                            {{ item.title }}
                                                        </p>
                                                    </div>
                                                </v-col>
                                                <v-col>
                                                    <v-row v-for="child in item.children" :key="child.title" no-gutters>
                                                        <v-col v-if="child.title" cols="auto">
                                                            <div class="min-w-60px min-w-40px min-w-md-60px">
                                                                <p>
                                                                    {{ child.title }}
                                                                </p>
                                                            </div>
                                                        </v-col>
                                                        <v-col>
                                                            <p v-html="child.info"></p>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </template>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualIntro from "@/components/client/sub/sub-visual-intro.vue";
import TabIntro from "@/components/client/sub/tab-intro.vue";
export default {
    components: {
        ClientPage,
        SubVisualIntro,
        TabIntro,
    },
    data() {
        return {
            items: [
                {
                    img: "/images/sub/company/supporters/supporters-img.jpg",
                    job: "배우",
                    name: "권해성",
                    nameEn: "KWON HAE SEONG",
                    history: [
                        {
                            title: "드라마",
                            children: [
                                {
                                    title: "2023",
                                    info: "야한사진관_정찬우 역 <br />화인가스캔들_서진수 역 <br />쿠팡플레이 미끼_경천형사 역",
                                },
                                {
                                    title: "2022",
                                    info: "OTT DMZ 대성동_유치헌 역 <br />KBS 미남당_고주원 역 <br />Netflix 더 패뷸러스_빈센트 역",
                                },
                                {
                                    title: "2021",
                                    info: "tvN 악마판사_공판검사 역",
                                },
                                {
                                    title: "2020",
                                    info: "JTBC 우아한 친구들",
                                },
                                {
                                    title: "2019",
                                    info: "OCN 달리는 조사관",
                                },
                                {
                                    title: "2018",
                                    info: "JTBC 뷰티 인사이드",
                                },
                                {
                                    title: "2017",
                                    info: "tvN 변혁의 사랑",
                                },
                            ],
                        },
                        {
                            title: "영화",
                            children: [
                                {
                                    title: "2023",
                                    info: "무저갱_한동훈 역",
                                },
                                {
                                    title: "2022",
                                    info: "고양막걸리사수대작전_경호실장 역 <br />그하룻밤_김병규 역 (주연) <br />2퍼센트_조동희 역 <br />아수라장_진검사 역 <br />부적_운철 역 (주연)",
                                },
                                {
                                    title: "2021",
                                    info: "엄마의 자리_최태호 역 <br />타이거 마스크_칠수 역",
                                },
                                {
                                    title: "2020",
                                    info: "새끼손가락_재철 역 <br />미소 (주연) <br />비온뒤 차차 (주연) <br />리얼 파이터 (주연) <br />강철의 연인 (주연)",
                                },
                                {
                                    title: "2019",
                                    info: "아내를 죽였다",
                                },
                                {
                                    title: "2017",
                                    info: "역모 - 반란의 시대",
                                },
                            ],
                        },
                        {
                            title: "공연",
                            children: [
                                {
                                    title: "2022",
                                    info: "갈매기_뜨레고린 역",
                                },
                                {
                                    title: "2021",
                                    info: "리어왕_에드가 역",
                                },
                            ],
                        },
                    ],
                },
                {
                    img: "/images/sub/company/supporters/supporters-img2.jpg",
                    name: "정가은",
                    nameEn: "JEONG GA EUN",
                    history: [
                        {
                            title: "방송",
                            children: [
                                { info: "MBC복면가왕, 라디오스타" },
                                { info: "TV조선 가족관계증명서" },
                                { info: "SBS fil 당신의 일상을 밝히는가" },
                                { info: "JTBC 다채로운 아침" },
                                { info: "TVN 롤러코스터 - 남녀탐구생활" },
                            ],
                        },
                        {
                            title: "영화",
                            children: [
                                { info: "별볼일 없는 인생" },
                                { info: "여자만화구두(극장판)" },
                                { info: "소녀괴담" },
                            ],
                        },
                        {
                            title: "드라마",
                            children: [
                                { info: "웹드라마 바닐라 라때 더블샷" },
                                { info: "JTBC 12년만의 재회, 달래 된, 장국" },
                                { info: "SBS 플러스 여자만화구두" },
                                { info: "SBS 주군의 태양" },
                                { info: "KBS 닥치고 패밀리" },
                            ],
                        },
                        {
                            title: "수상",
                            children: [
                                { info: "제4회 케이블 TV방송대상 올해의 스타상" },
                                { info: "제3회 대한민국 나눔대상 국회상임위원장상" },
                            ],
                        },
                    ],
                },
                {
                    img: "/images/sub/company/supporters/supporters-img3.jpg",
                    name: "최정은",
                    nameEn: "CHOE JEONG EUN",
                    history: [
                        {
                            title: "학력",
                            children: [
                                { info: "동덕여자대학교 스포츠학과 학사" },
                                { info: "동덕여자대학교 공연예술대학원 방송연예학과(모델전공)석사" },
                            ],
                        },
                        {
                            title: "경력",
                            children: [
                                { info: "모델컨텐츠학회 부회장" },
                                { info: "한국모델협회 이사" },
                                { info: "한국모델 지도자협회 이사" },
                                { info: "아시아모델페스티벌 조직위원" },
                                { info: "케이모델크루 아카데미&에이전시 대표" },
                                { info: "국민대학교 평생교육원 모델연기전공 총괄주임<br/>" },
                                { info: "서울종합예술학교 패션모델예술학부 학부장" },
                                { info: "우송정보대학 패션스타일디자인과 겸임교수" },
                                { info: "경문대학(현 국제)대 모댈과 외래교수" },
                                { info: "동덕여자대학교 모델과 특강강사" },
                            ],
                        },
                    ],
                },
                {
                    img: "/images/sub/company/supporters/supporters-img4.jpg",
                    name: "정여아",
                    nameEn: "JEONG YEO A",
                    history: [
                        {
                            title: "학력",
                            children: [
                                { info: "동덕여자대학교 대학원 석사" },
                                { info: "동덕여자대학교 스포츠학 학사 모델전공" },
                            ],
                        },
                        {
                            title: "경력",
                            children: [
                                { info: "2014~ 국민대학교 종합예술 곤서바토리 주임교수" },
                                { info: "2011.2~ 서울종합에술전문학교 패션모델예술학부 전임교수" },
                                { info: "동아방송대학교 방송연예학과 강사" },
                                { info: "동덕여자대학교 모델학과 강사" },
                                { info: "셀린느 모델" },
                                { info: "MCM 모델" },
                                { info: "펜디 모델" },
                                { info: "크리스찬 디올 모델" },
                                { info: "DKNY 모델" },
                                { info: "에스까다 모델" },
                                { info: "알마니 모델" },
                                { info: "패션쇼 서울컬렉션, SFAA, 쁘레따포르테 부산 모델" },
                            ],
                        },
                    ],
                },
                {
                    img: "/images/sub/company/supporters/supporters-img5.jpg",
                    name: "김봉희",
                    nameEn: "KIM PONG HUI",
                    history: [
                        {
                            title: "학력",
                            children: [
                                { info: "연세대학교 언론홍보대학원 광고홍보 석사" },
                                { info: "명지대학교 영어영문학과 졸업" },
                            ],
                        },
                        {
                            title: "경력",
                            children: [
                                { info: "순풍산부인과 데뷔. 웬만해선 그들을 막을 수 없다 외 OCN드라마 보이스, 블랙 출연" },
                                { info: "NS홈쇼핑 패션프로그램 ‘트랜드포유’ 진행" },
                                { info: "현대홈쇼핑 패션프로그램 “얼리버드 쇼” 진행(2019)" },
                                { info: "현대홈쇼핑 플러스샵 명품 프로그램 더 그레이스 진행" },
                                { info: "2020년 4월 베스트 쇼호스트 대표 표창 수상 (데님)" },
                                { info: "2020년 7월 슈퍼 루키 대박상 수상 (언더웨어)" },
                                { info: "2020년 건강기능식품, 다이어트 1등 수상 (비에날씬 다이어트)" },
                                { info: "2021년 하반기 행사 최고 매출 베스트 쇼호스트 수상" },
                                { info: "2021년 현대 홈쇼핑 1등 상품 선정 (비에날씬 다이어트)" },
                                { info: "2022년 현대 홈쇼핑 플러스 샵 슈퍼루키상 수상(보욜라 명품백)" },
                                { info: "2022년 현대 홈쇼핑 대표 패션 프로그램 독차지쇼 진행" },
                                { info: "2023년 cjonstyle 패션 프로그램 스튜디오 B 진행" },
                                { info: "국민대학교 모델연기 화술(스피치)겸임교수" },
                                { info: "대학 및 기업체 스피치 특강 및 이미지 특강" },
                            ],
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.chip {
    padding: 0 12px;
    height: 34px;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .chip {
        padding: 0 16px;
        height: 44px;
    }
}
@media (min-width: 1200px) {
}
</style>
